import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {Link} from 'gatsby';

const ServiceCard = ({input, classNames}) => (
  <div className={`service-card ${classNames}`}>
    <Link to={input.uid}>
      <Img fluid={input.data.portal_image.localFile.childImageSharp.fluid} className="w-100 h-auto" />
    </Link>
    <div className="f3 f2-l black g-medium lh-copy mt4 mb4">{input.data.service_name.text}</div>
    <div className="lh-copy f5 grey db mb3">{input.data.description.text}</div>
    <Link to={input.uid} className="link buttonlink yellow button semi-bold ttu f6 flex items-center tracked mt4">
      Read More
    </Link>
  </div>
);
export default ServiceCard;

ServiceCard.propTypes = {
  input: PropTypes.object.isRequired
};
