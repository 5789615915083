import React from 'react';
import Fade from 'react-reveal/Fade';

const Quote = ({classNames, quote}) => (
  <div className="mt4 mb4 mt6-l mb6-l pl4 pr4 flex-l items-center bg-tint-yellow pt5 pb5 pt6-l pb6-l">
    <Fade>
      <div className="f3 f2-l measure pl5-l pr5-l tc center lh-copy">{quote.text}</div>
    </Fade>
  </div>
);

export default Quote;
