import React from 'react';
import Img from 'gatsby-image';
import {Link} from 'gatsby';
import Fade from 'react-reveal/Fade';

const PromoBlock = ({classNames, image, title, text, linkText = '', link = ''}) => (
  <div className="mt4 mb4 mt6-l mb6-l pl4 pr4 pl5-l pr5-l flex-l items-center justify-between mw9 center">
    <Img fluid={image.localFile.childImageSharp.fluid} className="w-100 h-auto w-50-l" />
    <Fade>
      <div className="w-100 w-40-l pl4-l pr4-l">
        <h2 className="f3 f2-l g-medium lh-copy measure">{title.text}</h2>
        <div dangerouslySetInnerHTML={{__html: text.html}} className="f5 lh-copy grey" />
        {link.url && (
          <Link to={link.url} className="link buttonlink yellow button semi-bold ttu f6 flex items-center tracked mt4">
            {linkText.text}
          </Link>
        )}
      </div>
    </Fade>
  </div>
);

export default PromoBlock;
