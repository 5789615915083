import React from 'react';
import PropTypes from 'prop-types';
import ServiceCard from './ServiceCard';
import '../css/slick.css';
import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';

class ServiceSlider extends React.Component {
  constructor({props}) {
    super(props);
  }
  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Fade>
        <Slider {...settings} className="pl5-l pr5-l mb6 mw9 center">
          {this.props.input.map(serviceItem => (
            <ServiceCard input={serviceItem.node} classNames="pa4" />
          ))}
        </Slider>
      </Fade>
    );
  }
}

export default ServiceSlider;

ServiceSlider.propTypes = {
  input: PropTypes.object.isRequired
};
