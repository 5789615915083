import React from 'react';
import Img from 'gatsby-image';
import Fade from 'react-reveal/Fade';

class ImageBanner extends React.Component {
	constructor({ props }) {
		super(props);
		this.state = {
			isOpen: false
		};
		this.openModal = this.openModal.bind(this);
	}
	openModal() {
		this.setState({ isOpen: true });
	}
	render() {
		return (
			<div className="z-20 relative pl4 pl5-l w-100 vh-100 flex items-center">
				<Fade>
					<div className="mw7 z-1">
						<h1 className="white f2 f-subheadline-l mw7 g-medium lh-title mb3 mt0 pr4 pr6-l">
							{this.props.headline.text}
						</h1>
						<div className="white lh-copy mw7 pr4 measure-l f5 ">
							{this.props.bannerCaption.text}
						</div>
					</div>
				</Fade>
				<Img
					className="home-banner-image db top-0 left-0 obj-cover w-100 vh-100 absolute"
					fluid={this.props.poster.localFile.childImageSharp.fluid}
				/>
			</div>
		);
	}
}
export default ImageBanner;
