import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
// import VideoBanner from '../components/VideoBanner';
import PromoBlock from '../components/PromoBlock';
import MapContainer from '../components/MapContainer';
import Quote from '../components/Quote';
import ServiceSlider from '../components/ServiceSlider';
import ImageBanner from '../components/ImageBanner';

const IndexPage = ({ data: { prismicHomePage, services } }) => {
  const { data } = prismicHomePage;
  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      {/* <VideoBanner
				poster={data.poster_image}
				teaser={data.video_teaser}
				video={data.video_id}
				headline={data.headline}
				bannerCaption={data.banner_caption}
			/> */}
      <ImageBanner
        poster={data.poster_image}
        headline={data.headline}
        bannerCaption={data.banner_caption}
      />
      <PromoBlock
        image={data.feature_image}
        title={data.feature_title}
        text={data.feature_text}
        linkText={data.feature_link_text}
        link={data.feature_link}
      />
      <Quote quote={data.quote} />
      <ServiceSlider input={services.edges} />
      <div className="w-100 overflow-hidden relative map-container">
        <MapContainer location={data.location} />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    prismicHomePage {
      data {
        video_id {
          text
        }
        video_teaser {
          url
        }
        headline {
          text
        }
        banner_caption {
          text
        }
        poster_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        feature_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        feature_title {
          text
        }
        feature_text {
          html
        }
        feature_link {
          url
        }
        feature_link_text {
          text
        }
        quote {
          text
        }
        location {
          latitude
          longitude
        }
      }
    }
    services: allPrismicService(
      filter: { tags: { nin: "Content-Model" } }
      sort: { fields: [data___page_weight], order: ASC }
    ) {
      edges {
        node {
          uid
          data {
            page_weight
            service_name {
              text
            }
            description {
              text
            }
            portal_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
